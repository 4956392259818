<template>
  <HeaderHome/>

  <Loader :show="loading"/>
  <div class="container-fluid body">
    <div class="container">
      <div class="row body-box">
        <div class="col-md-12 top-voltar">
          <a href="javascript:history.back(-1);" title="Voltar">
            <span class="glyphicon glyphicon-chevron-left"></span> Voltar
          </a>
        </div>

        <div class="col-md-12">
          <h1>Minha Conta - Atualizar dados</h1>
          <p class="text-justify">Preencha os dados abaixo para atualizar sua conta na Ticket Experience.</p>
          <div class="container-fluid form-box">
            <form @submit.prevent="save_profile" ref="form">
              <div class="col-md-12">
                <h3>Dados pessoais</h3>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Nome *</label>
                  <input type="text" class="form-control" required="" v-model="user.first_name">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Sobrenome *</label>
                  <input type="text" class="form-control" required="" v-model="user.last_name">
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Data de nascimento *</label>
                  <input type="text" class="form-control" required="" v-mask="'##/##/####'" placeholder="__/__/____" autocomplete="off" maxlength="10" v-model="birthday">
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Sexo</label>
                  <select class="form-control" v-model="user.gender_id">
                    <option :value="gender.id" v-for="gender in genders" :key="gender.id">{{ gender.name }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>CPF *</label>
                  <input type="text" :value="user.cpf" disabled="disabled" class="aspNetDisabled form-control" required="" autocomplete="off" maxlength="14">
                </div>
              </div>
              <div class="col-md-12">
                <h3>Dados de acesso</h3>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>E-mail *</label>
                  <input type="text" :value="user.email" class="form-control" >
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Confirmar e-mail *</label>
                  <input type="text" v-model="user.email_confirmation" class="form-control" >
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>E-mail secundário</label>
                  <input type="text" v-model="user.email_second" class="form-control">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Nova senha</label>
                  <input type="password" v-model="user.password" class="form-control">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Confirmar nova senha</label>
                  <input type="password" v-model="user.password_confirmation" class="form-control">
                </div>
              </div>
              <div class="col-md-12">
                <h3>Dados complementares</h3>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Telefone</label>
                  <input type="text" v-model="user.telephone" class="form-control" v-mask="['(##) ####-####', '(##) #####-####']" autocomplete="off" maxlength="14">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Celular</label>
                  <input type="text" v-model="user.cellphone" class="form-control" v-mask="['(##) ####-####', '(##) #####-####']" autocomplete="off" maxlength="15">
                </div>
              </div>
              <div class="col-md-9">
                <div class="form-group">
                  <label>Endereço *</label>
                  <input type="text" v-model="user.street" class="form-control" required="">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Nº *</label>
                  <input type="text" v-model="user.number" class="form-control" required="">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Complemento</label>
                  <input type="text" v-model="user.complement" class="form-control">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Bairro *</label>
                  <input type="text" v-model="user.neighborhood" class="form-control" required="">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>UF</label>
                  <select class="form-control" @change="update_cities" v-model="user.state_id">
                    <option value="1">AC</option>
                    <option value="2">AL</option>
                    <option value="3">AM</option>
                    <option value="4">AP</option>
                    <option value="5">BA</option>
                    <option value="6">CE</option>
                    <option value="7">DF</option>
                    <option value="8">ES</option>
                    <option value="9">GO</option>
                    <option value="10">MA</option>
                    <option value="11">MG</option>
                    <option value="12">MS</option>
                    <option value="13">MT</option>
                    <option value="14">PA</option>
                    <option value="15">PB</option>
                    <option value="16">PE</option>
                    <option value="17">PI</option>
                    <option value="27">PR</option>
                    <option value="18">RJ</option>
                    <option value="19">RN</option>
                    <option value="20">RS</option>
                    <option value="21">RO</option>
                    <option value="22">RR</option>
                    <option value="23">SC</option>
                    <option value="24">SE</option>
                    <option value="25">SP</option>
                    <option value="26">TO</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Cidade *</label>
                  <select class="form-control" v-model="user.city_id" required="">
                    <option :value="index" v-for="(city,index) in cities" :key="index">{{ city }}</option>
                  </select>
                </div>
              </div>

              <div class="col-md-12">
                <label>
                  <input type="checkbox" v-model="user.receive_news"/>
                  Desejo receber o calendário TKX, dicas e oportunidades de
                  parceiros.
                </label>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <div class="row mt-4">
                    <div class="col-xs-6 col-md-3 col-md-offset-3  ">
                      <v-link href="/minha-conta/" class="btn btn-info">MINHA CONTA</v-link>
                    </div>
                    <div class="col-xs-6 col-md-3  text-right">
                      <button type="submit" class="btn btn-success">ATUALIZAR DADOS</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <Atalhos/>
        </div>
      </div>
    </div>
  </div>
  <Newsletter/>
  <Footer/>
  <FooterEnd/>
</template>

<script>
import {defineComponent} from "vue";
import HeaderHome from "../components/HeaderHome.vue";
import Footer from "../components/Footer.vue";
import FooterEnd from "../components/FooterEnd.vue";
import Newsletter from "../components/Newsletter.vue";
import Atalhos from "@/components/Atalhos";
import VLink from "../components/VLink";
import User from "@/api/User";
import Genders from "@/api/Genders";
import Loader from "../components/Loader";
import Auth from "@/api/Auth";
import {mask} from "vue-the-mask";
import Cities from "@/api/Cities";

import Swal from "sweetalert2";

export default defineComponent({
  name: "AtualizarDadosPage",
  directives: {mask},
  components: {
    HeaderHome,
    Footer,
    FooterEnd,
    Atalhos,
    Newsletter,
    VLink,
    Loader

  },
  computed: {
    birthday: {
      // getter
      get: function () {
        let data = (this.user.birthday).replace('T00:00:00.000000Z', '').split('-');
        let sqldata = data[2] + '-' + data['1'] + '-' + data[0];
        return sqldata;
      },
      // setter
      set: function (newValue) {
        if (newValue.length == 10) {
          let data = newValue.split('/');
          let sqldata = data[2] + '-' + data['1'] + '-' + data[0];
          this.user.birthday = sqldata;
        }
      }
    }
  },
  data() {
    return {
      loading: true,
      user: {
        "id": null,
        "state_id": null,
        "city_id": null,
        "gender_id": null,
        "first_name": null,
        "last_name": null,
        "email": null,
        "email_confirmation":'',
        "email_second": null,
        "password": null,
        "password_confirmation": null,
        "cpf": null,
        "birthday": "0000-00-00T00:00:00.000000Z",
        "telephone": null,
        "cellphone": null,
        "street": null,
        "number": null,
        "complement": null,
        "neighborhood": null,
        "receive_news": null,
        "full_name": null
      },
      genders: [],
      cities: [],
    };
  },
  methods:{
    update_cities(){
      this.user.city_id = null;
      this.cities = [];
      Cities.get_cities(this.user.state_id).then((request) => {
        if (request.status == 200) {
          this.cities = request.data;
        }
      });

    },
    save_profile(){
      if(this.$refs.form.checkValidity() == false){
        this.$refs.form.reportValidity();
      }

      User.update_profile(this.user,Auth.token()).then((request) => {
        if (request.status == 200) {
          this.user = request.data;
          Swal.fire({
            icon: 'success',
            title: 'Seus dados foram atualizados.',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
          });
        }else if (request.status == 422) {
          if(request.data.errors){
            if(request.data.errors.password){
              Swal.fire({
                icon: 'info',
                title: request.data.errors.password[0],
                showConfirmButton: true,
              });
            }
          }
        }else{
          console.log(request.data);
        }
      });
    }
  },
  mounted() {
    Genders.get_genders().then((request) => {
      if (request.status == 200) {
        this.genders = request.data;
      }
    });


    User.profile(Auth.token()).then((request) => {
      if (request.status == 200) {
        this.user = request.data;
        Cities.get_cities(request.data.state_id).then((request) => {
          if (request.status == 200) {
            this.cities = request.data;
          }
        });
      }
      this.loading = false;
    });
  },
});
</script>

<style scoped>

.mt-4 {
  margin-top: 30px;
}

.top-voltar {
  margin-bottom: 15px;
}

.top-voltar a {
  text-decoration: none;
  color: #d31f28;
}
</style>
